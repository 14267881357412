<template lang="pug">
    div.pa-10
        PageTitle(:label="pageLabel")

        div(v-acl.cant.hide='id ? `courier.edit` : `courier.add`') Você não possui a permissão necessária para acessar o recurso
        div(v-acl.can.hide='id ? `courier.edit` : `courier.add`')
          v-form(v-model="valid", ref="courierform")
              v-row
                  
                  v-col(cols="12", md="6", lg="4")
                    v-text-field(
                    outlined,
                    v-model="courier.name",
                    :rules="rules.name",
                    :counter="50",
                    label="Nome",
                    required
                    )

                    v-text-field(
                    outlined,
                    v-model="courier.lastname",
                    :rules="rules.lastname",
                    :counter="50",
                    label="Sobrenome",
                    required
                    )

                    v-text-field(
                    outlined,
                    v-model="courier.phone",
                    :rules="rules.phone",
                    label="Telefone",
                    required
                    )
                  
                    v-btn(color="success", x-large, @click="saveCourier", :disabled="!valid") Salvar
                        v-icon(right) mdi-content-save
                  v-col(cols="12", md="3", lg="3", align="center", justify="center")
                    v-avatar(color="grey lighten-3" size="200" rounded="0")
                      img( v-if="preview" :src="preview" style="object-fit: cover;")
                    div.grey--text.text-sm-body-2.mt-2.font-italic medidas 300x300 pixels
                    // div {{courier.image}}
                    input(type="file" @change="loadImage" ref="avatar" accept="image/png, image/jpeg" style="display:none")
                    v-btn.mt-5(color="primary", small, block, @click="selectImage") {{ (!preview) ? 'Selecionar imagem' : 'Alterar imagem' }}
</template>

<script>

export default {
  name: "Courier",
  components:{
    PageTitle: () => import( '@/components/PageTitle' ),
  },
  data: function() {
    return {
        preview: null,
        valid: false,
        id: null,
        courier: {
            id: null,
            name: "",
            lastname: "",
            telefone: "",
            image: null,
        },
        rules: {
        name: [
          v => !!v || v != "" || "Nome é obrigatório",
          v => v?.length >= 2 || "O Nome de conter mais de 2 caracteres"
        ],
        lastname: [v => !!v || v != "" || "Sobrenome é obrigatório"],
        phone: [v => !!v || v != "" || "Sobrenome é obrigatório"],
      } // rules
    }
  },

  mounted: function() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.loadData();
    }
  },
  
  methods: {
    loadData: async function() {
      if ( this.acl('courier.edit') ) {
        let response = await this.api.get(`courier/${this.id}`);
        this.courier = response.data;
        if(response.data.image_url){
          this.preview = response.data.image_url
        }    
      }  
    },

    selectImage: function() {
      this.$refs.avatar.click();
    },

    loadImage: function() {
      let input = this.$refs.avatar;
      let files = input.files;
      if (files && files[0]) {
        let reader = new FileReader();
        reader.onload = e => {
          this.preview = e.target.result;
          this.courier.image = e.target.result;
        }
        reader.readAsDataURL(files[0]);
      } else {
        this.preview = null;
        this.courier.image = null;
      }
      
    },
    saveCourier: function() {
        let self = this;

        if (this.valid) {
            if (!this.id) {
                // New
                this.api.post("courier", this.courier).then(function(res) {
                    if (res) {
                    self.$root.toast.show({ message: "Entregador criado com sucesso!" });
                    self.$refs.courierform.reset();
                    self.preview = null;
                    self.valid = false;
                    }
                });
            } else {
                // update

                this.api.put(`courier/${this.id}`, this.courier).then(function(res) {
                    if (res) {
                    self.$root.toast.show({
                        message: "Entregador editado com sucesso!"
                    });
                    }
                });
            }
        }
    },
  },
  computed: {
    pageLabel: function(){
        return  this.id ? `Editar Entregador - #${this.id}` : "Novo Entregador";
    },
  },
}
</script>